(function() {
    'use strict';

    angular
        .module('gwApp')
        .controller('PurchaseOrderEditController', PurchaseOrderEditController);

    PurchaseOrderEditController.$inject = ['$scope','$state', '$q', 'MessageService', 'DateUtils', 'PurchaseProductLine', 'Supplier', 'Depository', 'UnitMeasurement', 'Product', 'PurchaseOrder', 'entity','$uibModal', '$localStorage','$http'];

    function PurchaseOrderEditController ($scope, $state, $q, MessageService, DateUtils, PurchaseProductLine, Supplier, Depository, UnitMeasurement, Product, PurchaseOrder, entity,$uibModal, $localStorage, $http) {
        var vm = this;
        vm.productAdd = productAdd;
        vm.productDelete = productDelete;
        vm.getWarehouse = getWarehouse;
        vm.types = [
            {name:'成品采购', value:'Outsourcing'},
            {name:'材料采购', value:'RawMaterial'}
        ];
        vm.deletedList = []
        vm.token = $localStorage.authenticationToken;
        vm.purchaseOrder = entity;
        vm.suppliers = Supplier.getAll();
        vm.update = true;
        $q.all([vm.purchaseOrder.$promise,vm.suppliers.$promise]).then(function(){
            if(vm.purchaseOrder.status != 'New'){
                vm.update = false;
            }
            init();
        });

        function init() {
            if(vm.purchaseOrder.type =='Outsourcing'){
                vm.warehousis = Depository.getAll({type: 'product'});
            }else if(vm.purchaseOrder.type =='RawMaterial'){
                vm.warehousis = Depository.getAll({type: "accessories"});
            }else{
                vm.warehousis = Depository.getAll({type: null});
            }

            vm.deliveryDate = vm.purchaseOrder.deliveryDate == null ? null : vm.purchaseOrder.deliveryDate.substr(0, 10);
            $q.all([vm.warehousis.$promise]).then(function(){
                getProduct(function (){
                    if(vm.purchaseOrder.purchaseProductLines && vm.purchaseOrder.purchaseProductLines.length>0){
                        // 为了选择sku时的优化  将已删除的商品单拿出来  避免污染正在选择的商品及sku
                        var _tempList = []
                        vm.purchaseOrder.purchaseProductLines.forEach(function(itm){
                            if(itm.cancelled){
                                vm.deletedList.push(itm)
                            }else{
                                _tempList.push(itm)
                            }
                        })
                        vm.purchaseOrder.purchaseProductLines = _tempList

                        var _ids = []
                        vm.purchaseOrder.purchaseProductLines.forEach(function (line){
                            if(vm.purchaseOrder.status == 'AlreadyIssued' && !line.warehousingNum){
                                line.warehousingNum = line.num;
                            }
                            $scope.selectPurchaseProduct(line)
                            if(!line.propertyConfigId && line.propertyConfigSku && line.propertyConfigs && line.propertyConfigs.length>0){
                                for(var i=0; i<line.propertyConfigs.length; i++){
                                    if(line.propertyConfigSku == line.propertyConfigs[i].sku){
                                        line.propertyConfigId=line.propertyConfigs[i].id;
                                        _ids.push(line.propertyConfigs[i].id)
                                        break
                                    }
                                }
                            }
                        })
                        setOptions(_ids)
                    }
                })
            });
        }

        // 筛选掉选过的option
        function setOptions(propertyConfigIds){
            vm.purchaseOrder.purchaseProductLines.forEach(function(item,idx){
                // 把除当前propertyConfigId的item筛选出来
                var ids1 = propertyConfigIds.filter(function(ids){
                    return ids !== item.propertyConfigId
                })
                // 去掉所有已选择的propertyConfigId 的item
                var _filteredArray = item.propertyConfigs.filter(function(item) {
                    var _idx0 = ids1.findIndex(function (itm){
                        return item.id == itm
                    })
                    return _idx0 == -1;
                });
                item.propertyConfigs = _filteredArray
            })
        }

        function getProduct(callback){
            if(vm.purchaseOrder.type == 'Outsourcing'){
                vm.products = Product.getAllByCondition({items:[{key:"category",op:"=",value:'GeneralProduct'}]});
            }else{
                vm.products = Product.getAllByCondition({items:[{key:"category",op:"=",value:'MaterialProduct'}]});
            }
            if(callback){
                $q.all([vm.products.$promise]).then(function(){
                    callback();
                });
            }
        }


        $scope.selectStatus = function(){
            if(vm.purchaseOrder.type =='Outsourcing'){
                vm.warehousis = Depository.getAll({type: 'product'});
            }else if(vm.purchaseOrder.type =='RawMaterial'){
                vm.warehousis = Depository.getAll({type: "accessories"});
            }else{
                vm.warehousis = Depository.getAll({type: null});
            }
            getProduct(function (){
                vm.purchaseOrder.purchaseProductLines = [];
            })
        };

        //去掉选中入库之后的sku null
        $scope.selectPurchaseProduct = function(data){
            data.propertyConfigs=[];
            data.propertyConfigId=null;
            if(data.productId){
                for(var i=0; i<vm.products.length; i++){
                    if(data.productId == vm.products[i].id){
                        data.propertyConfigs=vm.products[i].propertyConfigs;
                        data.measurementName=vm.products[i].measurementName;
                        data.measurementId=vm.products[i].measurementId;
                        data.productName=vm.products[i].name;
                        break
                    }
                }
            }
            //筛选出其他行上选择同商品下的sku 并对当前商品的skuList重新赋值
            var arr = []
            vm.purchaseOrder.purchaseProductLines.forEach(function(item){
                if(item.productId == data.productId && item.propertyConfigId){
                    arr.push(item.propertyConfigId)
                }
            })
            var filteredArray = data.propertyConfigs.filter(function(item) {
                var _idx = arr.findIndex(function (itm){
                    return item.id == itm
                })
                return _idx == -1;
            });
            data.propertyConfigs = filteredArray
        };
        // 选择sku时 更新非当前行上的其他同一商品的skuList
        $scope.selectPurchaseProductSku = function(data,index){
            // scm编号赋值
            data.propertyConfigs.forEach(function(cfg){
                if(cfg.id == data.propertyConfigId){
                    data.propertyConfigNumber  = cfg.number
                }
            })
            // 过滤sku下拉选框
            var propertyConfigIds = []
            vm.purchaseOrder.purchaseProductLines.forEach(function(item,idx){
                if(index !== idx){
                    if(item.productId == data.productId){
                        // 收集所有propertyConfigId
                        vm.purchaseOrder.purchaseProductLines.forEach(function(line){
                            propertyConfigIds.push(line.propertyConfigId)
                        })
                        // 赋值赋值全量propertyConfigs
                        for(var i=0; i<vm.products.length; i++){
                            if(item.productId == vm.products[i].id){
                                item.propertyConfigs=vm.products[i].propertyConfigs;
                                break
                            }
                        }
                        // 把除当前propertyConfigId的item筛选出来
                        propertyConfigIds = propertyConfigIds.filter(function(ids){
                            return ids !== item.propertyConfigId
                        })

                        // 去掉所有已选择的propertyConfigId 的item
                        var _filteredArray = item.propertyConfigs.filter(function(item) {
                            var _idx1 = propertyConfigIds.findIndex(function (itm){
                                return item.id == itm
                            })
                            return _idx1 == -1;
                        });
                        item.propertyConfigs = _filteredArray
                    }
                }
            })
        };

        function productAdd() {
            if(!vm.purchaseOrder.type){
                MessageService.error("请选择采购类型");
                return
            }
            if (vm.purchaseOrder.purchaseProductLines == undefined) {
                vm.purchaseOrder.purchaseProductLines = [];
            }
            var purchaseProductLine = {
                productId:null,
                num: 0,
                price: 0,
                tax: vm.taxRate || 0,
                propertyConfigId:null,
                propertyConfigs: [],
            };
            vm.purchaseOrder.purchaseProductLines.push(purchaseProductLine);
        }

        function productDelete(record,index) {
            // 删除时如果当前行选择了sku 则把此项sku加入到其他行的skuList中
            if(record.propertyConfigId){
                var currentSku = record.propertyConfigs.find(function(config){
                    return config.id == record.propertyConfigId
                })
                vm.purchaseOrder.purchaseProductLines.forEach(function(item,idx){
                    if(item.productId == record.productId){
                        item.propertyConfigs.push(currentSku)
                    }
                })
            }
            var _data = JSON.parse(JSON.stringify(vm.purchaseOrder.purchaseProductLines))
            if(_data[index].id){
                _data[index].cancelled = true;
                vm.deletedList.push(_data[index])
                _data.splice(index, 1);
            }else{
                _data.splice(index, 1);
            }
            vm.purchaseOrder.purchaseProductLines = _data
        }

        // 默认入库，需要考虑商品的类型
        function getWarehouse() {
            if(vm.purchaseOrder.warehouseId){
                vm.warehousis.forEach(function (item){
                    if(item.id == vm.purchaseOrder.warehouseId){
                        vm.purchaseOrder.warehouseName = item.name;
                        vm.purchaseOrder.warehouseType = item.type;
                        vm.purchaseOrder.contacts = item.contacts;
                        vm.purchaseOrder.phone = item.phone;
                        vm.purchaseOrder.address = item.province + item.city + item.district + item.address;
                    }
                })
            }else{
                vm.purchaseOrder.warehouseName = null;
                vm.purchaseOrder.warehouseType = null;
                vm.purchaseOrder.contacts = null;
                vm.purchaseOrder.phone = null;
                vm.purchaseOrder.address = null;
            }
        }

        function getDate(strDate) {
            var st = strDate;
            var a = st.split(" ");
            var b = a[0].split("-");
            var c = a[1].split(":");
            var date = new Date(b[0], b[1] - 1, b[2], c[0], c[1], c[2]);
            return date;
        }

        $scope.save = function(saveAndSubmit,callback){
            if(vm.isSaving){return }
            if (!vm.purchaseOrder.supplierId) {
                MessageService.error("请选择供应商");
                return false;
            }
            if (vm.purchaseOrder.type == null || vm.purchaseOrder.type == '') {
                MessageService.error("请选择采购类型");
                return false;
            }

            var deliveryDate = getDate($('#startDate').val() + " 23:59:59");
            vm.purchaseOrder.deliveryDate = deliveryDate;
            if (vm.purchaseOrder.deliveryDate == null || vm.purchaseOrder.deliveryDate == '') {
                MessageService.error("请选择计划交期");
                return false;
            }
            if (!vm.purchaseOrder.warehouseId) {
                MessageService.error("请选择默认入库");
                return false;
            }
            if (vm.purchaseOrder.contacts == null || vm.purchaseOrder.contacts == '') {
                MessageService.error("请输入联系人");
                return false;
            }
            if (vm.purchaseOrder.phone == null || vm.purchaseOrder.phone == '') {
                MessageService.error("请输入联系电话");
                return false;
            }
            if (vm.purchaseOrder.address == null || vm.purchaseOrder.address == '') {
                MessageService.error("请输入收货地址");
                return false;
            }
            if (vm.purchaseOrder.freight === null || vm.purchaseOrder.freight === '' || vm.purchaseOrder.freight < 0) {
                MessageService.error("请输入运费");
                return false;
            }
            if(vm.purchaseOrder.purchaseProductLines && vm.purchaseOrder.purchaseProductLines.length>0){
                var purchaseProductLines = [];
                vm.deletedList.forEach(function(delItem){
                    vm.purchaseOrder.purchaseProductLines.push(delItem)
                })
                vm.purchaseOrder.purchaseProductLines.forEach(function (item){
                    if(!item.cancelled){
                        purchaseProductLines.push(item)
                    }
                })
                if (purchaseProductLines.length == 0) {
                    MessageService.error("商品信息不能为空");
                    return false;
                }
                for (var i = 0; i < vm.purchaseOrder.purchaseProductLines.length; i++) {
                    var purchaseProductLine = vm.purchaseOrder.purchaseProductLines[i];
                    if(!purchaseProductLine.cancelled){
                        if (!purchaseProductLine.productId || !purchaseProductLine.propertyConfigId) {
                            MessageService.error("商品信息中的商品选择不完整");
                            return false;
                        }
                        if (purchaseProductLine.num == null || purchaseProductLine.num <= 0) {
                            MessageService.error("请输入商品信息中的数量");
                            return false;
                        }
                        if (purchaseProductLine.price == null) {
                            MessageService.error("请输入商品信息中的单价");
                            return false;
                        }

                        if (purchaseProductLine.tax === null || purchaseProductLine.tax === '' || purchaseProductLine.tax < 0) {
                            MessageService.error("请输入商品信息中的税率");
                            return false;
                        }
                        vm.purchaseOrder.purchaseProductLines[i].total = (purchaseProductLine.num * purchaseProductLine.price).toFixed(2);
                        vm.purchaseOrder.purchaseProductLines[i].taxPrice = ((purchaseProductLine.price * purchaseProductLine.tax / 100) + purchaseProductLine.price).toFixed(4);
                        vm.purchaseOrder.purchaseProductLines[i].taxTotal = vm.purchaseOrder.purchaseProductLines[i].taxPrice * purchaseProductLine.num;
                    }
                }

            }else {
                MessageService.error("商品信息不能为空");
                return false;
            }

            vm.isSaving = true;
            if(saveAndSubmit){
                PurchaseOrder.saveAndSubmit(vm.purchaseOrder, function(res){
                    vm.isSaving = false;
                    MessageService.success('新建采购成功！');
                    $scope.cancelSave();
                },function(error){
                    vm.isSaving = false;
                    MessageService.error('新建采购失败');
                });
            }else{
                if(vm.purchaseOrder.status == 'AlreadyIssued'){
                    PurchaseOrder.changeWarehousingNum(vm.purchaseOrder, function(res){
                        vm.isSaving = false;
                        MessageService.success('保存成功！');
                        $scope.cancelSave();
                    },function(error){
                        vm.isSaving = false;
                        MessageService.error('编辑采购失败');
                    });
                    return
                }
                if(vm.purchaseOrder.id){
                    PurchaseOrder.update(vm.purchaseOrder, function(res){
                        vm.isSaving = false;
                        if(callback){
                            callback()
                        }else{
                            MessageService.success('编辑采购成功！');
                            $scope.cancelSave();
                        }
                    },function(error){
                        vm.isSaving = false;
                        MessageService.error('编辑采购失败');
                    });
                }else{
                    PurchaseOrder.save(vm.purchaseOrder, function(res){
                        vm.isSaving = false;
                        if(callback){
                            callback()
                        }else{
                            MessageService.success('新建采购成功！');
                            $scope.cancelSave();
                        }
                    },function(error){
                        vm.isSaving = false;
                        MessageService.error('新建采购失败');
                    });
                }
            }
        };

        //导出
        $scope.export = function () {
            $http({
                url: 'api/purchaseOrder/exportDetail/'+vm.purchaseOrder.id,
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer " + vm.token
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "采购单"+ vm.purchaseOrder.number + '_' +DateUtils.convertLocalDateToServer(Date.now())+ ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        };

        $scope.cancelSave = function() {
            history.back();
        }

        $scope.getSupplier = function() {
            vm.purchaseOrder.supplierName = null;
            vm.taxRate = 0;
            if(vm.purchaseOrder.supplierId){
                vm.suppliers.forEach(function (item){
                    if(item.id == vm.purchaseOrder.supplierId){
                        vm.purchaseOrder.supplierName =item.name;
                        vm.taxRate = item.taxRate;
                    }
                })
            }
            if(vm.purchaseOrder.purchaseProductLines && vm.purchaseOrder.purchaseProductLines.length>0){
                vm.purchaseOrder.purchaseProductLines.forEach(function (item){
                    item.tax = vm.taxRate;
                })
            }
        }
        //通过或拒绝
        $scope.audit = function (item){
            if(!item){
                MessageService.confirm("是否审核拒绝", function () {
                    PurchaseOrder.changeState({ids:vm.purchaseOrder.id,state:'Close'}, function(res){
                        if(res.state == 400){
                            MessageService.error(res.message || '拒绝失败');
                            return
                        }
                        MessageService.success('已拒绝');
                        $scope.cancelSave();
                    },function(error){
                        MessageService.error('拒绝失败');
                    });
                });
            }else{
                MessageService.confirm("确认审核通过", function () {
                    PurchaseOrder.changeState({ids:vm.purchaseOrder.id,state:'AlreadyIssued'}, function(res){
                        if(res.state == 400){
                            MessageService.error(res.message || '通过失败');
                            return
                        }
                        MessageService.success('已通过');
                        $scope.cancelSave();
                    },function(error){
                        MessageService.error('通过失败');
                    });
                });
            }
        }
        //关闭
        $scope.close = function (){
            MessageService.confirm("确认关闭当前采购单", function () {
                PurchaseOrder.changeState({ids:vm.purchaseOrder.id,state:'Close'}, function(res){
                    if(res.state == 400){
                        MessageService.error(res.message || '拒绝失败');
                        return
                    }
                    MessageService.success('已拒绝');
                    $scope.cancelSave();
                },function(error){
                    MessageService.error('拒绝失败');
                });
            });
        }
        $scope.numChange = function (purchaseProductLine){
            purchaseProductLine.warehousingNum = purchaseProductLine.num;
            $scope.currentItemChange(purchaseProductLine)
        }
        $scope.currentItemChange = function (purchaseProductLine){
            // 限制用户只能输入两位小数
            if(purchaseProductLine.price){
                var value = purchaseProductLine.price;
                var regex = /^(\d+(\.\d{0,4})?)$/;
                if (!regex.test(value)) {
                    var parts = String(value).split('.')
                    if (parts[1].length > 4) {
                        value = parts[0] + '.' + parts[1].substring(0, 4);
                    }
                    purchaseProductLine.price = Number(value)
                }
            }
            purchaseProductLine.taxPrice = ((purchaseProductLine.price * purchaseProductLine.tax / 100) + purchaseProductLine.price).toFixed(4)
            purchaseProductLine.taxTotal = purchaseProductLine.taxPrice * purchaseProductLine.num
        }
        //登记入库
        $scope.changeWarehousingNum = function (data){
            $uibModal.open({
                templateUrl: 'app/purchase/warehousing.html',
                controller: 'WarehousingController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    msgdata: function() {
                        return {
                            purchaseOrder:vm.purchaseOrder,
                            purchaseOrderList:data
                        };
                    }
                }
            }).result.then(function(result){
                if(result){
                    vm.purchaseOrder = result;
                    init();
                }
            });
        }
        //完成入库
        $scope.constraint = function (){
            MessageService.confirm("确认完成入库", function () {
                vm.isSaving = true;
                PurchaseOrder.changeWarehousingNum(vm.purchaseOrder, function(res){
                    PurchaseOrder.changeState({ids: vm.purchaseOrder.id, state: 'compulsoryWarehousing'}, function (data) {
                        MessageService.success('采购订单完成入库成功！');
                        $scope.cancelSave();
                        vm.isSaving = false;
                    }, function (error) {
                        MessageService.error('采购订单完成入库失败！');
                        vm.isSaving = false;
                    });
                },function(error){
                    vm.isSaving = false;
                    MessageService.error('编辑采购失败');
                });
            });
        }
        $scope.purchaseOrderType = function (item){
            switch (item) {
                case 'New':
                    return {
                        text:'待提交',
                        color:'#333'
                    };
                case 'ToBeAudited':
                    return {
                        text:'待审核',
                        color:'#ffa037'
                    };
                case 'ToBeRevised':
                    return {
                        text:'已关闭',
                        color:'#333'
                    };
                case 'AlreadyIssued':
                    return {
                        text:'未入库',
                        color:'#ff0000'
                    };
                case 'Store':
                    return {
                        text:'部分入库',
                        color:'#51de37'
                    };
                case 'Finished':
                    return {
                        text:'已完成',
                        color:'#005eff'
                    };
                case 'Examined':
                    return {
                        text:'审核通过',
                        color:'#333'
                    };
                default:
                    return {
                        text:item,
                        color:'#333'
                    };
            }
        }
    }
})();
